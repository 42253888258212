import { Box, Flex, Image, Text } from '@chakra-ui/react'

import { ChakraLink } from '@/modules/components/ChakraLink'
import MainBadge from '@/modules/components/MainBadge'

export const GuideSection = () => {
  const displayGuides = [
    {
      key: 1,
      title: 'はじめての正社員就職！転職活動の流れとポイントをわかりやすく解説',
      href: `https://zcareer.com/guide/detail/a4lz2b8q0kvi`,
      category: '転職ノウハウ',
      url: 'https://images.microcms-assets.io/assets/903131ef99244f6dbf69e7b558624923/ef26035de6324485a108d02fe4b0d72a/22.webp',
    },
    {
      key: 2,
      title: 'はじめての正社員転職で失敗しない仕事選びのコツ',
      href: `https://zcareer.com/guide/detail/65wl--v10c`,
      category: '仕事・求人の探し方',
      url: 'https://images.microcms-assets.io/assets/903131ef99244f6dbf69e7b558624923/4859cc3bf4bb440cb8708ca4cfc2de4a/25.jpg',
    },
    {
      key: 3,
      title: 'はじめての正社員転職でも安心！企業の目を引く履歴書・職務経歴書の書き方',
      href: `https://zcareer.com/guide/detail/y-ervj06h`,
      category: '履歴書・職務経歴書の書き方',
      url: 'https://images.microcms-assets.io/assets/903131ef99244f6dbf69e7b558624923/fea886a8ece342e993c77fa0eb551137/23.webp',
    },
    {
      key: 4,
      title: '失敗から学ぶ！面接官から好印象をゲットする6つのコツ',
      href: `https://zcareer.com/guide/detail/347cc2gcc`,
      category: '面接対策',
      url: 'https://images.microcms-assets.io/assets/903131ef99244f6dbf69e7b558624923/b90cdaa9f07441d8a3a77b7a1b459ca8/24.webp',
    },
  ]
  return (
    <Flex w="full" alignItems="center" flexDirection="column" gap="16px">
      {displayGuides.map((v) => (
        <ChakraLink w="full" key={v.key} href={v.href} linkTo="guide" isExternal>
          <Flex
            w="full"
            alignItems="flex-start"
            justifyContent="space-between"
            pb="16px"
            borderBottom="1px solid #E2E8F0"
          >
            <Box mr="16px">
              <Text textStyle="talent.heading.sm" mb="8px">
                {v.title}
              </Text>
              <MainBadge text={v.category} />
            </Box>
            <Image w="112px" borderRadius="4px" src={v.url} alt={v.title} />
          </Flex>
        </ChakraLink>
      ))}
    </Flex>
  )
}
