import { GetServerSidePropsContext } from 'next'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { getMeApiForSSR } from '@/api/authApi'
import { getPrefecturesWithJobCounts } from '@/api/masterAPi'
import { getAllScoutApi } from '@/api/scoutApi'
import { TopPageWrapper } from '@/components/top/TopPageWrapper'
import { appUrl } from '@/config'
import { META } from '@/const/meta'
import { useAuthCheck } from '@/hooks/useAuth'
import AppLayout from '@/layout/AppLayout'
import serverSideLogger from '@/modules/utils/serverSideLogger'
import { PrefecturesWithJobCount } from '@/oas/public/api'
import { MeTalent, Scouts } from '@/oas/talent/api'
import Error from '@/pages/_error'

type Props = {
  data: {
    jobs: PrefecturesWithJobCount | null
    isLogin: boolean
    me: MeTalent | null
    scouts: Scouts | null
  }
  status: number
}

const Home: React.FC<Props> = (props: Props) => {
  const { data, status } = props
  const router = useRouter()
  // SSRで取得したログイン情報をglobal stateに保存
  useAuthCheck(data.me)
  if (status !== 200) {
    return <Error statusCode={status} />
  }

  const { title } = META.home
  const { description } = META.home
  const canonicalUrl = `${appUrl}${router.asPath.split('?')[0]}`

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <AppLayout>
        <TopPageWrapper
          isLogin={data.isLogin}
          me={data.me}
          prefecturesWithJobCount={data.jobs}
          scouts={data.scouts}
        />
      </AppLayout>
    </div>
  )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
  try {
    const cookieName = 'firebase-session-cookie'
    const authCookie = context.req.cookies?.[cookieName]
    // まず、ログイン状態を確認して、ログインしている場合はログインユーザー情報を取得
    const meRes = authCookie ? await getMeApiForSSR(authCookie, context) : null

    // 並列で実行する処理をPromise.allでまとめる
    const [prefecturesRes, scoutRes] = await Promise.all([
      getPrefecturesWithJobCounts(),
      meRes?.status === 200 ? getAllScoutApi(undefined, authCookie) : Promise.resolve(null),
    ])
    return {
      props: {
        data: {
          jobs: prefecturesRes.data,
          isLogin: meRes?.status === 200,
          me: meRes ? meRes.data : null,
          scouts: scoutRes ? scoutRes.data : null,
        },
        status: prefecturesRes.status,
      },
    }
  } catch (err) {
    serverSideLogger.error({
      msg: err,
    })
    throw err
  }
}

export default Home
